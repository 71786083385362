.wrapper{
	min-height:100vh;
}

html{
	overflow-x:hidden;
}

body{
	font-family: "futura_regular";
	font-size: 17px;
	color: #999;

	background: #222;
}

.container{
	max-width: 1170px;
}