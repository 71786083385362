.paginacao{
	margin-top:20px;
	margin-bottom:30px;

	> *{
		display: inline-block;
		padding:6px 12px;
		margin: 0 2.5px;
		color: #FFF;

		border-radius: 50%;
		background: #b0b0b0;
	}

	.active,
	*:hover{
		text-decoration:none;
		color: #FFF;
		background: $cor-default;
	}
}
