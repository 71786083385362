.rodape{
	background: $bg-rodape;

	.content-black{
		font-family: "latolight";
		font-size: 13px;
		color: #666;

		padding: 40px 0;
		background: #111;

		figure,
		span{
			display: inline-block;
			vertical-align: middle;
		}
	}

	.creditos{
		font-family: "latolight";
		font-size: 12px;
		color: #111;

		padding: 30px 0;
		background: $bg-creditos;

		p{
			margin-bottom: 2.5px;

			&:first-child{
				font-size: 14px;
			}
		}
	}

	// @include media-breakpoint-down(sm){
	// 	.content-black{
	// 		.figure-inline{
	// 			// display:none
	// 		}
	// 	}
	// }
}

.rodape-diferentao{
	.figure-inline{
		display:inline-block;
	}

	.content-black .figure-inline{
		max-width:213px;
	}
}
