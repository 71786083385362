.carousel-dados{
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:100%;
	display:flex;
	align-items:flex-end;
	justify-content:flex-end;

	.dados-content{
		position:relative;
		z-index:50;
		background:rgba(28,28,28,0.5);
		max-width:1000px;
		margin:0 auto;
		text-align:center;
		width:100%;
		padding:10px 20px;
	}
}

@media (max-width: $screen-sm-min){

	.rodape-diferentao{
		padding-top:0;

		.text.c-white{
			margin-top:0;
			margin-bottom:80px;
		}
	}

	.carousel-dados{
		position: relative;
	}

	.banners{
		margin-bottom:0;
	}

	.dados-content{
		background:rgb(28,28,28);
		padding: 0 20px !important;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;

		span{
			display:block;
		}

		h1{
			margin-bottom:0;
		}

		.page-header{
			margin-top:0;
			order:1;
		}

		.botoes-acesso{
			order:2;
			margin-top:0 !important;
			margin-bottom:25px;
			width:100%;
		}

		.text{
			order:4;
		}

		.hidden-md.hidden-lg{
			order:3;
		}

	}

}
