.ic-pager-prev{
	@include make-icon(67px, 47px, 0 0);
}

.ic-pager-next{
	@include make-icon(67px, 47px, 0 -57px);
}

.ic-logo{
	@include make-icon(24px, 47px, 0 -232px);
}

.ic-facebook{
	@include make-icon(22px, 23px, 0 -289px);
}

.ic-twitter{
	@include make-icon(22px, 23px, 0 -322px);
}

.ic-instagram{
	@include make-icon(22px, 23px, 0 -351px);
}

.ic-square-whatsapp{
	@include make-icon(30px, 30px, 0 -380px);
}

.ic-square-facebook{
	@include make-icon(30px, 30px, 0 -420px);
}

.ic-square-twitter{
	@include make-icon(30px, 30px, 0 -460px);
}

.ic-square-email{
	@include make-icon(30px, 30px, 0 -500px);
}

.ic-square-plus{
	@include make-icon(30px, 30px, 0 -540px);
}