.mobile-ctrls{
	display:flex;
	justify-content:space-between;
	border-bottom:1px solid rgba(#fff,0.2);

	.search,
	.menu-ctrls{
		display:flex;
	}

	.btn-ctrls{
		border: 1px solid transparent;
		display: inline-block;
		padding:10px 15px;
		width:50px;

		@include button-variant(#222222, #222222);
		color:#FFF;

		&[aria-expanded="true"] .fa-bars:before{
			content: $fa-var-times
		}
	}

	.brand{
		padding:5px;
		width:220px;
		max-width: calc(100% - 100px);
	}
}

#menu{
	list-style:none;
	padding-left:0;
	margin-bottom:0;
	color:#FFF;

	a{
		display: block;
	}
}

.topo{
	.btn-ctrls{
		&:focus{
			outline: none;
		}
	}

	.content{
		.btn-ctrls{
			display: block;
			padding:6px 12px;
			border:1px solid transparent;
			@include button-variant(#FFF, transparent, transparent);

			&:focus{
				color: #BA922E;
			}
		}
	}

	.campo-busca{
		display: flex;

		input{
			@extend .form-control;
			border-radius:0;
			background: #111111;
			border-color:#111;
		}

		button{
			padding:6px 12px;
			border:1px solid transparent;
			@include button-variant(#111, #111)
			color:#BA922E;
		}
	}
}

@media (min-width: $screen-md-min){
	.mobile-ctrls{
		display: none;
	}

	.topo{
		position:relative;

		.content{
			padding:20px 15px;
			max-width:1170px;
			margin-left:auto;
			margin-right: auto;
			display: flex;
			align-items: center;
		}

		.campo-busca{
			width:280px;
			margin-left:auto;
			position: relative;

			&:before{
				content: '';
				display: block;
				position: absolute;
				right:10px;
				bottom:100%;
				border:10px solid transparent;
				border-bottom-color: #111;
			}

			input{
				border-radius:5px 0 0 5px;
			}

			button{
				border-radius:0 5px 5px 0;
			}
		}

		.brand{
			position:relative;
			zindex:100;
		}
	}

	.menu{
		margin-left: auto;
	}

	#menu{
		display:flex;

		a{
			padding:6px 12px;
		}

		.active a,
		a:hover{
			text-decoration: none;
			color: #BA922E;
		}
	}

	.search-area{
		position:absolute;
		width:100%;
		top: calc(100% - 48px);
		z-index:10;
		display: none;

		&.open{
			display: block;
		}
	}
}

@media (max-width: $screen-sm-max){
	.topo {
		position: sticky;
		top:0;
		background: #222222;
		z-index:999;

		.content{

			.brand,
			.search{
				display: none;
			}

		}
	}

	#menu {
		a{
			padding: 10px 15px;
		}

		.active a,
		a:hover{
			background: #BA900E;
				color:#FFF;
		}
	}

	.search-area{
		display: none;
		padding:10px;

		&.open{
			display:block;
			animation: fadeIn 0.4s linear;
		}

		button{
			border-left-color: rgba(#fff,0.2) !important;
		}
	}
}

#navigation .btn-ctrls{
	@include button-variant(transparent, transparent);

	&:hover{
		color: #BA922E;
		background: lighten(#1C1C1C, 10%)
	}
}