$icons: (
    'br': 0 0 48px 33px,
    'en': 0 -33px 48px 33px,
    'es': 0 -66px 49px 33px,
);

.icon{
    display:inline-block;
    background-image: url(#{$imgs}/sprite.png);
    background-repeat:no-repeat;

    &.block{
        display:block;
    }
}

@mixin sprites($icons) {
    @each $icon,$data in $icons{
        .#{$icon}{
            width: nth($data,3);
            height: nth($data,4);
            background-position: #{nth($data,1)} #{nth($data,2)};
        }
    }
}

@include sprites($icons);