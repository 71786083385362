.banners{
	position: relative;

	.pager{
		position: absolute;
		top: 50%;
		margin: 0;
		z-index: 19;

		@media (max-width: $screen-xs-max){
			display: none;
		}

		&.pager-prev{
			right: 100%;
			z-index:100;
			transform: translateY(-50%) translateX(50%);
		}

		&.pager-next{
			left: 100%;
			z-index:100;
			transform: translateY(-50%) translateX(-50%);
		}

		&:hover{

			&.pager-prev{
				.icon{
					background-position: 0 -115px;
				}
			}

			&.pager-next{
				.icon{
					background-position: 0 -173px;
				}
			}

		}
	}
}

.banners{
	margin-bottom: 40px;
}

.banner-default{

	.item-default{
		position: relative;

		.photo{
			position: relative;

			&:before{
				content: "";
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				display:block;
				height: 100%;
				z-index: 9;
				background: rgba(255,255,255,0.1);
				background-size:cover;
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(185,185,185,0) 29%, rgba(0,0,0,1) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(2%, rgba(255,255,255,0)), color-stop(29%, rgba(185,185,185,0)), color-stop(100%, rgba(0,0,0,1)));
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(185,185,185,0) 29%, rgba(0,0,0,1) 100%);
				background: -o-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(185,185,185,0) 29%, rgba(0,0,0,1) 100%);
				background: -ms-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(185,185,185,0) 29%, rgba(0,0,0,1) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,0) 2%, rgba(185,185,185,0) 29%, rgba(0,0,0,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
				background-repeat:no-repeat;
			}
		}

		.dados{
			position: absolute;
			bottom: 0;
			left: 0;

			padding-left: 50px;
			padding-bottom: 50px;

			z-index: 10;

			.nome{
				// font-family: "";
				font-size: 25px;
				color: #FFF;
				font-style: italic;
				margin-bottom: 10px;

				&:before{
					content: "";

					display: block;
					width: 54px;
					height: 5px;

					margin-bottom: 20px;
					background: $cor-default;
				}
			}

			.phone{
				font-size: 20px;
				color: $cor-default;
			}
		}
	}
}

.redes-sociais{
	@media (min-width: $screen-sm-min){
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	@media (max-width: $screen-xs-max){
		text-align: center;
		display:flex;
		flex-wrap:wrap;

		figure{
			margin-bottom: 15px;
			padding:0 5px;
			max-width:50%
		}
	}
}

.modelo-default{
	position: relative;
	max-width:365px;
	margin-left:auto;
	margin-right:auto;

	figure{
		margin-bottom:0;
	}

	.item-default{
		border: 2px solid #999;

		&:hover{
			border-color: $cor-default;;
		}

		.dados{
			padding: 5px 0;
			text-align: center;
			font-size: 14px;
			color: #FFF;
			background: #333;

			@include media-breakpoint-down(sm) {
				// font-size: .75rem;
			}

			.nome{
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: 1.5;
				// @include resumo(14px, 1.2, 2);
				overflow: hidden;
				max-height: 1.5em * 2;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;

				a{
					display: block;
					white-space: nowrap;
				}
			}
		}

		.pagamento{
			text-align: center;
			font-size: 14px;
			color: #FFF;
			padding: 5px 0;

			// position: absolute;
			// top: calc(100% + 2px);
			left: 0;
			width: 100%;
			z-index: 10;

			&.pag-black{
				border-left: 2px solid #000;
				border-right: 2px solid #000;
				background: #000;
			}
		}
	}
}

.noticia-internas-detalhes{
	.item-default{
		.data{
			float: left;
			font-size: 10px;
			color: #666;

		}
		.hora{
			float: right;
			font-size: 10px;
			color: #666;
		}

		.dados{
			.titulo{
				margin: 15px 0 30px;
				// font-family: "";
				font-size: 24px;
				color: $cor-default;;
				font-weight: bold;
				line-height: 110%;

				@include media-breakpoint-down(sm) {
					font-size: 1rem;
				}
			}

			.desc{
				font-size: 14px;
				color: #666;
			}
		}

		.text{
			font-size: 15px;

			@include media-breakpoint-down(sm) {
				font-size: .75rem;
			}
		}

		.tags{
			margin-top: 25px;
			font-size: 14px;
			color: #FFF;

			padding: 15px 20px;
			border-top: 1px solid rgba(#999, .3);
			border-bottom: 1px solid rgba(#999, .3);

			span,
			ul,
			ul li{
				display: inline-block;
				vertical-align: middle;
			}

			span{
				margin-right: 15px;
			}

			ul li{
				color: $cor-default;

				&:not(:last-child){
					&:after{
						content: ",";

						display: inline-block;
						vertical-align: middle;
						margin: 0 .5px;
					}
				}
			}
		}

		footer{
			color: #FFF;
			margin-top: 25px;
			margin-bottom: 55px;

			padding: 8px 15px;
			// border-top: 1px solid rgba(#999, .3);
			// border-bottom: 1px solid rgba(#999, .3);

			display: flex;
			align-items: center;

			flex-wrap: wrap;

			.social{
				@media (max-height: $screen-xs-max){
					width: 100%;
				}
				margin-left: 25px;
				margin-right: 25px;

				a{
					display: inline-block;
					vertical-align: middle;

					margin: 0 2.5px;

					&:first-child{
						margin-left: 0;
					}

					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
}

.noticia-default{
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid #999;

	.item-default{
		@media (min-width: $screen-sm-min){
			display: flex;
			justify-content: space-between;
			align-items: stretch;
		}

		.photo{
			border: 2px solid #999;
			max-width: 348px;

			@include media-breakpoint-down(sm){
				margin-left:auto;
				margin-right:auto;
			}

			@media (min-width: $screen-sm-min){
				min-width: 348px;
			}
			border-radius: 25px;
			overflow: hidden;
		}

		.dados{
			margin-left: 15px;

			.data{
				float: left;
				font-size: 10px;
				color: #666;

			}

			.hora{
				float: right;
				font-size: 10px;
				color: #666;
			}

			.titulo{
				margin: 10px 0 15px;
				// font-family: "";
				font-size: 24px;
				color: $cor-default;
				font-weight: bold;
				text-decoration: underline;

				line-height: 110%;

				// @include media-breakpoint-down(sm) {
				// 	font-size: 1rem;
				// }
			}

			.desc{
				font-size: 14px;
				color: #666;
				text-decoration: underline;

				line-height: 110%;

				// @include media-breakpoint-down(sm) {
				// 	font-size: .75rem;
				// }
			}
		}

		footer{
			margin-top: 25px;
			padding: 8px 15px;
			border-top: 1px solid rgba(#999, .3);
			border-bottom: 1px solid rgba(#999, .3);

			display: flex;
			justify-content: space-between;
			align-items: center;

			flex-wrap: wrap;

			.social{
				@media (max-height: $screen-xs-max){
					width: 100%;
				}

				a{
					display: inline-block;
					vertical-align: middle;

					margin: 0 2.5px;

					&:first-child{
						margin-left: 0;
					}

					&:last-child{
						margin-right: 0;
					}
				}
			}

			> a{
				font-size: 13px;
				color: $cor-default;
			}
		}
	}
}

.noticia-inline-default{

	.item-default{
		display: flex;
		justify-content: space-between;
		align-items: center;

		.nro{
			font-size: 50px;
			color: #FFF;
			margin-right: 20px;

			// @include media-breakpoint-down(sm) {
			// 	font-size: 2rem;
			// }
		}

		.titulo{
			font-size: 14px;
			color: #666;
			// @include media-breakpoint-down(sm) {
			// 	font-size: .75rem;
			// }
		}
	}
}

.aside-noticias{
	margin-bottom: 40px;

	header{
		padding: 20px 25px;
		background: $cor-default;
		@include clearfix();

		h3{
			font-family: "zing_rust_demobase";
			font-size: 22px;
			color: #FFF;

			margin: 0;
		}
	}

	.content{
		padding: 10px 25px;
		background: #161616;

		.noticia-inline-default{
			&:not(:last-child){
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: 1px solid #666;
			}
		}
	}
}

.content-carousel{
	position: relative;

	@media (max-width: $screen-xs-max){
		text-align:center;

		display: flex;
		flex-wrap: wrap;
		justify-content:center;

		.owl-carousel{
			width:100%;
			order:1;
		}

		.pager-prev{
			order:2;

			// .icon{
			// 	// background-position: 0 -115px;
			// }
		}

		.pager-next{
			order:3;
			// .icon{
			// 	// background-position: 0 -173px;
			// }
		}
	}

	.pager{
		position: absolute;
		top: 50%;
		margin: 0;
		z-index: 19;

		@media (max-width: $screen-xs-max){
			position: relative !important;
			transform: none !important;
			left:0 !important;
			right:0 !important;
		}

		&:hover{

			&.pager-prev{
				.icon{
					background-position: 0 -115px;
				}
			}

			&.pager-next{
				.icon{
					background-position: 0 -173px;
				}
			}

		}

		&.pager-prev{
			right: calc(100% - 30px);
			transform: translateY(-50%) translateX(-50%);
		}

		&.pager-next{
			left: calc(100% - 30px);
			transform: translateY(-50%) translateX(50%);
		}
	}
}

.link-default{
	.item-default{
		@media (min-width: $screen-sm-min){
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		padding-bottom: 40px;
		margin-bottom: 15px;
		border-bottom: 1px solid rgba(#999, .3);

		.dados{
			margin-bottom: 25px;
			@media (min-width: $screen-sm-min){
				width: 70%;
			}

			.nome{
				font-size: 23px;
				color: $cor-default;
				margin-bottom: 15px;

				@include media-breakpoint-down(sm) {
					font-size: 1rem;
				}
			}

			.desc{
				font-size: 15px;
				color: #FFF;


				@include media-breakpoint-down(sm) {
					font-size: .75rem;
				}
			}
		}

		a{
			font-size: 14px;
			color: #FFF;
			padding: 15px 10px;
			text-align: center;
			min-width: 161px;
			border-radius: 45px;
			background: $cor-default;


			@include media-breakpoint-down(sm) {
				font-size: .75rem;
			}
		}
	}
}

.modelos-miniaturas{
	margin-bottom: 40px;

	@include media-breakpoint-down(sm){
		margin-bottom:0;

		figure{
			margin-bottom:15px;
		}
		.modelo-default{
			margin-bottom:0;
		}
	}

	.modelo-default{
		.item-default{
			border: none;
		}
	}
}

#acompanhantes{
	display:flex;
	flex-wrap: wrap;

	@media (max-width: $screen-xs-min){
		& > *{
			width: 100%;
		}
	}
}
