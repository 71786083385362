.logo__frase{
	color: #C1903F;
	text-align: center;
	font-size: 12px;
}

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
    @extend .img-fluid;
}

.owl-nav{
    display: none;
}

.unlistenner{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.redes-sociais{
    padding-top: 30px;
    // margin-bottom: 25px;
    // background: #1c1c1c;
}

.bg-black{

    & > .bg-dark{
        background:$bg-body !important;
    }

    // margin-bottom: 25px;
    @media (min-width: $screen-sm-min){
        padding: 0 30px;
    }

    @include clearfix();
    background: #1c1c1c;

    .page-header{
        margin:40px 0 25px 0;

        & + .mgt-40{
            margin-top: 0 !important;
        }

        @include media-breakpoint-down(sm){
            margin:25px 0 25px 0;
        }
    }
}

.bg-dark{
    margin: 20px 0;
    padding: 5px 15px;
    @media (min-width: $screen-sm-min){
        padding: 20px 30px;
    }
    @include clearfix();
    background: #111;

    header{
        margin-top: 0;

        h1{
        	padding-left: 0;
        	text-align: center;
        	font-size: 28px;
        }
    }
}

.c-white{
    color: #FFF;
}

.c-gold{
    color: #cb9d0c;
}

.btn{
    padding: 15px 5px;
    min-width: 208px
}

.btn-gold{
    color: #FFF;
    background: #cb9d0c;
}

.btn-black{
    color: #FFF;
    background: #000;

    &:hover{
        background: #cb9d0c;
    }
}

.form-group{
    margin-bottom: 17px;
}

.form-select{
    position: relative;

    select{
        position: absolute;
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;

        border: none;
        background: transparent;

        &:focus{
            outline: none;
        }
    }
}

textarea{
    resize: none;
}

.form-control{
    min-height: 50px;
    border-color: #252525;
    background: #252525;

    font-size: 13px;
    color: #F1F1F1;

    @include placeholdIt(){
        color: #999;
    }

    &:focus{
        outline: none;
    }
}

.redes-block{
    figure{
        margin-bottom: 22px;
    }
}

.br-0{
    border-radius: 0 !important;
}

.after-border-right{

    @include media-breakpoint-up(sm){

        &:after{
            content: "";
            position: absolute;
            top: 0;
            left: calc(100% - 5px);
            width: 1px;
            height: 100%;
            background: rgba(#999, .3);
        }

    }

}

.fz-16{
    font-size: 16px;

	// @include media-breakpoint-down(sm) {
	// 	font-size: .75rem;
	// }
}

.border-gray{
    border: 1px solid #363636;
}

.table{
    tbody{
        tr{
            &:nth-child(odd){
                background: #1c1c1c;
            }

            td{
                font-size: 15px;
                border-top: none;

                &:nth-child(odd){
                    color: $cor-default;
                }

                &:nth-child(even){
                    text-align: right;
                }
            }
        }
    }
}

.modelo-dados{
    font-size: 15px;
    border-bottom: 1px solid rgba(#999, .3);

    // @include media-breakpoint-down(sm) {
    // 	font-size: 12px;

    // 	h3,h4{
    // 		font-size: 16px;
    // 	}
    // }
}

.title-name{
    font-weight: 500;
    font-size: 23px;
    color: $cor-default;
    padding-bottom: 20px;
    margin-bottom: 20px;

    border-bottom: 1px solid rgba(#999, .3);


    // @include media-breakpoint-down(sm) {
    // 	font-size: 16px;
    // }
}

.compartilhe{
    span,
    .social{
        display: inline-block;
        vertical-align: middle;
    }

    span{
        margin-right: 15px;
        color: $cor-default;
    }

    .social{
        a{
            display: inline-block;
            vertical-align: middle;
            margin: 0 2.5px;

            &:first-child{
                margin-left: 0;
            }

            &:last-child{
                margin-left: 0;
            }
        }
    }
}

.social span{
    margin-right:0;
}

.botoes-acesso{
    a{
        display: inline-block;
        vertical-align: middle;
        margin: 0 7.5px;

        @media (max-width: $screen-xs-max){
            margin: 10px 0;
        }


        &:first-child{
            margin-left: 0;
        }

        &:last-child{
            margin-right: 0;
        }
    }
}

.wrapper{
    min-height:30vw;
}

body{
    background:$bg-body;
}

.hidden-sm{
    @include media-breakpoint-only(sm){
        display: none;
    }
}

.hidden-lg{
    @include media-breakpoint-up(lg){
        display: none;
    }
}

.hidden-md{
    @include media-breakpoint-only(md){
        display: none;
    }
}

// aweogh

.row-custom{
    @include media-breakpoint-down(sm){

        .col-6{
            padding-left:2.5px !important;
            padding-right:2.5px !important;
        }
    }
}

.video-ensaio{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    position: relative;

    &:before{
        content: '';
        padding-bottom: percentage(9 / 16);
        display: block;
    }

    iframe, video{
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        border: none;
    }
}
