@font-face {
    font-family: 'zing_rust_demobase';
    src: url('#{$fonts}/zingrushdemo_base/fonts/zingrustdemo-base-webfont.woff2') format('woff2'),
         url('#{$fonts}/zingrushdemo_base/fonts/zingrustdemo-base-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
	font-family: 'futura_regular';
	src: url('#{$fonts}/futura_regular/fonts/FuturaBT-Book.eot');
	src: url('#{$fonts}/futura_regular/fonts/FuturaBT-Book.eot?#iefix') format('embedded-opentype'),
		url('#{$fonts}/futura_regular/fonts/FuturaBT-Book.woff') format('woff'),
		url('#{$fonts}/futura_regular/fonts/FuturaBT-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
    font-family: 'latolight';
    src: url('#{$fonts}/lato_light/fonts/lato-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_light/fonts/lato-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}