body{
	@include media-breakpoint-down(sm) {
		font-size: #{(14 / 16) * 1rem};
	}
}

a{
	color:inherit;

	&:hover,
	&:focus{
		outline: none;
		text-decoration: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	border-bottom: none;
	margin: 40px 0 25px;
	padding-bottom: 0;

	.icon{
		display: inline-block;
		vertical-align: middle;
	}

	h1{
		font-family: "zing_rust_demobase";
		font-size: 28px;
		color: #cb9d0c;

		text-transform: uppercase;
		padding-left: 34px;

		&:after{
			content:'';
			display: table;
			clear: both;
		}

		.icon{
			align-self: flex-start;
			margin-right: 10px;
			margin-left: -34px;
			float: left;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1rem;
		}
	}
}

h4{
	@include media-breakpoint-down(sm) {
		font-size: 20px;
	}
}

@include media-breakpoint-down(sm) {
	.btn{
		font-size: .75rem;
	}

	.form-control{
		font-size: .75rem;
	}
}

select{
	color: #F1F1F1;
}

.h1__capa {
	padding-left: 0 !important;

	@include media-breakpoint-down(sm) {
		font-size: 28px !important;
	}
}
