.flex-atendimento{
	.line:nth-child(odd){
		background: #1C1C1C;
	}

	.line{
		display: flex;
		flex-wrap:wrap;
		font-size: 15px;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
		}

		& > * {
			padding:8px;
		}

	}

	.title{
		color: #ba900e;
		flex-grow:1;
	}

	@media (max-width: $screen-xs-max){
		border:1px solid rgba(#fff,0.1);
		margin-bottom:10px;

		.content{
			min-width:250px;
		}
	}

	@media (min-width: $screen-md-min){
		.content{
			text-align: right;
		}
	}
}

.outras-informacoes{
	display:flex;
	flex-wrap:wrap;

	.line:nth-child(odd){
		background: #1C1C1C;
	}

	.line{
		display: flex;
		flex-wrap:wrap;
		font-size: 15px;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
		}

		& > * {
			padding:8px;
		}

	}

	.title{
		color: #ba900e;
		flex-grow:1;
	}

	.columns{
		flex-grow:1;
	}

	@media (min-width: $screen-sm-min){
		.columns{
			max-width:50%;
		}
	}

	@media (max-width: $screen-xs-max){
		border:1px solid rgba(#fff,0.1);
		margin-bottom:10px;

		.title{
			min-width:180px;
		}

		.columns:nth-child(even){
			.line:nth-child(even){
				background: #1C1C1C;
			}

			.line:nth-child(odd){
				background: none;
			}
		}
	}
}
